/* eslint-disable camelcase */
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle, Loader, ModalSignal, TextInput } from 'components'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Chantier, Collection, IndiceGep } from 'types'
import { ChantierSignal, getChantiers, linkChantierToIndice } from 'services/chantiers'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import CollectionPanel from 'pages/home/panels/collection/CollectionPanel'

import './CodeSelectModal.scss'

interface Props {
  title: string;
  collection: Collection
  indice: IndiceGep
}

export default function CodeSelectModal({ title, collection, indice }: Props) {
  const [selected, setSelected] = useState<string>(indice.code_c6 || '')
  const handleSearch = useCallback(debounce((value: string) => {
    setSelected(value)
    getChantiers(value)
  }, 500), [])

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleSelect = (chantier: Chantier) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation()
    ChantierSignal.lists.value = []
    setSelected(chantier.num_compte_geremi)
  }

  const handleValidate = async () => {
    const collectionUpdated = await linkChantierToIndice(indice.id, selected, collection.id)
    if (collectionUpdated) PanelSignal.value = <CollectionPanel collection={collectionUpdated} />
  }

  useEffect(() => () => {
    ChantierSignal.lists.value = []
  }, [])

  return (
    <div className="modal-code-select" onClick={() => { ChantierSignal.lists.value = [] }}>
      <h3>{title}</h3>
      <div className="search">
        <TextInput
          bindedValue={selected}
          onChange={handleSearch}
          label="Code C6"
          autoFocus
          RightIcon={ChantierSignal.loading.value ? <Loader variant="x-small" /> : <Chevron />}
        />
        <div className="results hide-scroll">
          <table>
            {ChantierSignal.lists.value.filter(({ num_compte_geremi }) => !!num_compte_geremi).map(chantier => (
              <tr key={chantier.id} className="result" onClick={handleSelect(chantier)}>
                <td className="code">{chantier.num_compte_geremi}</td>
                <td className="name">{chantier.libelle}</td>
                <td className="astre">{chantier.numero_astre}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
        <Button text={terms.Common.continue} onClick={handleValidate} isAsync />
      </div>
    </div>
  )
}
