import { signal } from '@preact/signals-react'
import { patch, RequestManager } from '@osrdata/app_core/dist/requests'
import { Chantier, Collection, PaginatedResponse } from 'types'
import { catchable } from 'utils'
import { ModalSignal, ToastLevel, ToastSignal } from 'components'
import { getCollection } from './collections'

const rq = new RequestManager()

export const ChantierSignal = {
  lists: signal<Chantier[]>([]),
  loading: signal<boolean>(false),
}

export const getChantiers = async (search?: string) => {
  rq.abort()
  ChantierSignal.loading.value = true
  const [error, response] = await catchable(() => rq.get<PaginatedResponse<Chantier>>(
    '/dexcarto/pte/chantiers/',
    search && {
      search,
    },
  ))

  if (!error) {
    ChantierSignal.lists.value = response.results
  }

  if (!error || error !== 'canceled') {
    ChantierSignal.loading.value = false
  }
}

export const linkChantierToIndice = async (indiceId: number, code: string, collectionId: string) => {
  const [error] = await catchable(() => patch(`/dexcarto/ouranos/indices/${indiceId}/`, { code_c6: code }), true)

  if (!error) {
    const [, response] = await catchable<Collection>(() => getCollection(collectionId))
    ToastSignal.value = {
      severity: ToastLevel.SUCCESS,
      message: 'Indice lié avec succès',
    }
    ModalSignal.value = undefined

    return response
  }

  return null
}
