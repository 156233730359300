import { Feature } from 'geojson'
import { ReactComponent as MapsIcon } from 'assets/logos/maps.svg'
import imajnetIconPath from 'assets/logos/imajnet.png'
import { CHARTIS_SOURCES_IDS } from 'services'
import { LayerFeature } from 'types'

type Props = {
  feature: LayerFeature
  sourceLayer: string
}

const getLatLon = (feature: Feature) => {
  const { geometry } = feature

  if (!geometry?.type) return null

  if (geometry.type === 'Point') {
    return [geometry.coordinates[1], geometry.coordinates[0]]
  }

  if (geometry.type === 'LineString' || geometry.type === 'MultiPoint') {
    return [geometry.coordinates[0][1], geometry.coordinates[0][0]]
  }

  if (geometry.type === 'MultiLineString') {
    return [geometry.coordinates[0][0][1], geometry.coordinates[0][0][0]]
  }

  return null
}

export default function MapLinks({ feature, sourceLayer }: Props) {
  const featureLatLon = feature && getLatLon(feature as Feature)
  const imajnetUrl = featureLatLon && `https://web.imajnet.net/#loc=${featureLatLon[0]},${featureLatLon[1]};zoom=12;`
  const googleMapsUrl = featureLatLon && `http://maps.google.com/maps?t=k&q=loc:${featureLatLon[0]}+${featureLatLon[1]}`

  if (
    CHARTIS_SOURCES_IDS.adv !== sourceLayer
    && CHARTIS_SOURCES_IDS.localisateur !== sourceLayer
    && CHARTIS_SOURCES_IDS.pedale !== sourceLayer
    && CHARTIS_SOURCES_IDS.acces !== sourceLayer
    && CHARTIS_SOURCES_IDS.dbc !== sourceLayer
    && CHARTIS_SOURCES_IDS.passageNiveau !== sourceLayer
    && CHARTIS_SOURCES_IDS.jdz !== sourceLayer
    && CHARTIS_SOURCES_IDS.station !== sourceLayer
    && CHARTIS_SOURCES_IDS.sousStation !== sourceLayer
    && CHARTIS_SOURCES_IDS.css !== sourceLayer
    && CHARTIS_SOURCES_IDS.signal !== sourceLayer
    && CHARTIS_SOURCES_IDS.poste !== sourceLayer
    && CHARTIS_SOURCES_IDS.bal !== sourceLayer
    && CHARTIS_SOURCES_IDS.bif !== sourceLayer
    && CHARTIS_SOURCES_IDS.center !== sourceLayer
    && CHARTIS_SOURCES_IDS.rac !== sourceLayer
    && CHARTIS_SOURCES_IDS.cdv !== sourceLayer
  ) return null

  return (
    <div className={`maps-links ${featureLatLon ? '' : 'disabled'}`}>
      <a href={imajnetUrl} target="__blank" rel="noreferrer">
        <img src={imajnetIconPath} alt="Imajnet" />
        Imajnet
      </a>
      <a href={googleMapsUrl} target="__blank" rel="noreferrer">
        <MapsIcon />
        Google Maps
      </a>
    </div>
  )
}
