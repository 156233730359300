import { CHANTIER_LAYERS_PHASES, CHANTIER_LAYERS_YEARS, POSTES_LAYER_KEYS } from 'assets/layers'
import { Polygon } from 'geojson'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import { OnScreenBALSearchResult, OnScreenChantierSearchResult, OnScreenPosteSearchResult } from 'types'
import { catchable } from 'utils'
import { isLayerSelected } from './layers'
import { CHARTIS_SOURCES_IDS } from './map'

const rq = new RequestManager()

const getOnScreenPoste = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const posteTypes = Object.entries(POSTES_LAYER_KEYS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  if (!posteTypes.length) return false
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat_centroid' : 'full_rgi_track_geo_centroid'
  const [error, response] = await catchable(() => rq.get<OnScreenPosteSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.poste}/search/${viewParam}/`,
    {
      // eslint-disable-next-line max-len
      columns: 'id,libelle_long,type_installation_fixe_id_mnemo,libelle,lrs_ligne,lrs_voie,lrs_pk',
      bbox,
      size_per_layer: 50,
      ...(posteTypes.length ? { type_installation_fixe_id_mnemo__in: posteTypes.join(',') } : {}),
      ...additionalParams,
    },
  ))
  if (error) return error
  return response
}

const getOnScreenBAL = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  if (!isLayerSelected('bal-zone')) return false
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const [error, response] = await catchable(() => rq.get<OnScreenBALSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.bal}/search/${viewParam}/`,
    {
      columns: 'id,libelle,lrs_ligne,lrs_pk',
      bbox,
      size_per_layer: 50,
      ...additionalParams,
    },
  ))
  if (error) return error
  return response
}

const getOnScreenChantier = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const years = Object.entries(CHANTIER_LAYERS_YEARS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  const phases = Object.entries(CHANTIER_LAYERS_PHASES).filter(([, value]) => isLayerSelected(value))
    .map(([key]) => key)
  if (!years.length || !phases.length) return false
  const filterASTRE = isLayerSelected('chantier-astre') || isLayerSelected('chanter-astre-and-seism')
  const filterSEISM = isLayerSelected('chantier-seism') || isLayerSelected('chanter-astre-and-seism')
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const [error, response] = await catchable(() => rq.get<OnScreenChantierSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.chantier}/search/${viewParam}/`,
    {
      columns: 'id,libelle,num_compte_geremi,numero_astre,lrs_ligne,lrs_voie,lrs_pk,annee,date_mes_production',
      bpolygon: bbox,
      size_per_layer: 50,
      ...(years.length ? { annee__in: years.join(',') } : {}),
      ...(phases.length ? { phase__in: phases.join(',') } : {}),
      ...(filterASTRE && { numero_astre__isnull: false }),
      ...(filterSEISM && { seism_project_id__isnull: false }),
      ...additionalParams,
    },
  ))
  if (error) return error
  return response
}

export const onScreenSearch = async (bbox: Polygon, view: 'sch' | 'geo', additionalParams?: Record<string, string>) => {
  rq.abort()
  const [error, response] = await catchable(() => Promise.all([
    getOnScreenPoste(bbox, view, additionalParams),
    getOnScreenBAL(bbox, view, additionalParams),
    getOnScreenChantier(bbox, view, additionalParams),
  ]))
  if (response.some(r => r === 'canceled')) return 'canceled'
  return {
    poste: typeof response[0] === 'string' || error ? false : response[0],
    bal: typeof response[1] === 'string' || error ? false : response[1],
    chantier: typeof response[2] === 'string' || error ? false : response[2],
  }
}
