import { signal } from '@preact/signals-react'
import { CSSProperties } from 'react'

import './Tooltip.scss'

export const TooltipSignal = {
  label: signal<string>(null),
  style: signal<CSSProperties>(null),
}

export default function TooltipWrapper() {
  if (!TooltipSignal.label.value) return null
  return <span className="tooltip-label" style={TooltipSignal.style.value}>{TooltipSignal.label.value}</span>
}
