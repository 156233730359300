/* eslint-disable camelcase */
import { signal } from '@preact/signals-react'
import { get, post, RequestManager } from '@osrdata/app_core/dist/requests'
import { Collection, ObjectCollection, ObjectFA, PaginatedResponse } from 'types'
import { catchable } from 'utils'
import { CHARTIS_SOURCES_IDS } from './map'

const rq = new RequestManager()

export const CollectionSignal = {
  lists: signal<Collection[]>([]),
  loading: signal<boolean>(false),
  next: null,
}

export const ObjectCollectionSignal = {
  loading: signal<boolean>(false),
  postes: signal<{id: string, label: string}[]>([]),
  collections: signal<Collection[]>([]),
  fa: signal<ObjectFA[]>([]),
}

export const getCollection = async (id: string) => {
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<Collection>(`/dexcarto/ouranos/collections/${id}/`))
  CollectionSignal.lists.value = CollectionSignal.lists.value.map(c => (c.id === id ? response : c))
  CollectionSignal.loading.value = false
  return !error ? response : null
}

export const getCollections = async (search?: string) => {
  CollectionSignal.lists.value = []
  CollectionSignal.loading.value = true
  rq.abort()

  const [error, response] = await catchable(
    () => rq.get<PaginatedResponse<Collection>>('/dexcarto/ouranos/collections/', search && {
      search,
    }),
  )

  if (!error) {
    CollectionSignal.lists.value = response.results
    CollectionSignal.next = response.next
  }

  if (!error || error !== 'canceled') {
    CollectionSignal.loading.value = false
  }
}

export const getNextCollections = async () => {
  if (!CollectionSignal.next) return
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<PaginatedResponse<Collection>>(CollectionSignal.next))
  if (!error) {
    CollectionSignal.lists.value = [...CollectionSignal.lists.value, ...response.results]
    CollectionSignal.next = response.next
  }
  CollectionSignal.loading.value = false
}

export const getObjectCollections = async (layer_slug: string, object_id: string) => {
  ObjectCollectionSignal.loading.value = true
  ObjectCollectionSignal.postes.value = []
  ObjectCollectionSignal.collections.value = []
  const [error, response] = await catchable(() => post<ObjectCollection[]>(
    '/dexcarto/ouranos/object-collections/',
    { object_id, layer_slug },
  ))
  if (!error) {
    ObjectCollectionSignal.postes.value = response.flatMap(r => r.postes_rattachement.map(poste => ({
      id: poste.id,
      label: poste.libelle_long,
    })))
    ObjectCollectionSignal.collections.value = response.flatMap(r => r.collections)
  }
  if (layer_slug === CHARTIS_SOURCES_IDS.chantier) {
    const [err, resp] = await catchable(() => get<ObjectFA[]>(
      `/dexcarto/pte/${object_id}/fiches/`,
    ))
    if (!err) {
      ObjectCollectionSignal.fa.value = resp
    }
  } else {
    ObjectCollectionSignal.fa.value = []
  }
  ObjectCollectionSignal.loading.value = false
}
