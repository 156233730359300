import { POSTES_LAYER_KEYS } from 'assets/layers'
import { Layer, Source } from 'react-map-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, isLayerSelected, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { getCollectionStatus, hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'

const getPosteFilter = () => {
  const posteTypes = Object.entries(POSTES_LAYER_KEYS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  const expression: mapboxgl.Expression = ['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', posteTypes]]
  return expression
}
export default function PosteLayer({
  view, mapRef, visibility,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.poste,
    view,
    CHARTIS_SOURCES_IDS.poste,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal
  const collectionProps = getCollectionStatus()
  return (
    <Source
      id={CHARTIS_SOURCES_IDS.poste}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.posteCircle}
        type="circle"
        source-layer={CHARTIS_SOURCES_IDS.poste}
        paint={{
          'circle-stroke-color': '#3492da',
          'circle-stroke-width': 1,
          'circle-radius': 5,
          'circle-color': hoverColor('#ffb612', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter.value, ['coalesce', ['get', 'lrs_ligne'], '']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={9}
        filter={getPosteFilter()}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.posteLabel}
        type="symbol"
        source-layer={CHARTIS_SOURCES_IDS.poste}
        paint={{
          'text-halo-color': '#ffb612',
          'text-halo-width': 0.1,
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-size': 14,
          'text-anchor': 'left',
          'symbol-placement': 'point',
          'text-allow-overlap': false,
          'text-offset': [1, 0],
          'text-justify': 'auto',
          'text-field': [
            'format',
            ['get', 'gare_libelle'],
            '\n',
            ['get', 'libelle'],
            '\n',
            {},
            ['get', 'type_installation_fixe_id_mnemo'],
          ],
          visibility: visibility ? 'visible' : 'none',
          'symbol-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, 1],
        }}
        minzoom={9}
        filter={getPosteFilter()}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.posteCollectionStatus}
        type="symbol"
        source-layer={CHARTIS_SOURCES_IDS.poste}
        layout={{
          'icon-image': [
            'case',
            ['==', collectionProps.count, 0], 'green_circle',
            ['<=', collectionProps.count, 5], 'yellow_circle',
            ['<=', collectionProps.count, 10], 'orange_circle',
            'red_circle',
          ],
          'icon-size': 0.075,
          'icon-offset': [0, -270],
          'text-field': ['to-string', collectionProps.count],
          'text-font': ['Open Sans Regular'],
          'icon-allow-overlap': false,
          'text-allow-overlap': false,
          'text-offset': [0, -1.7],
          'text-size': 12,
          'symbol-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, 1],
          visibility: collectionProps.visibility ? 'visible' : 'none',
        }}
        paint={{
          'text-opacity': 1,
        }}
        filter={collectionProps.filter}
        minzoom={8}
      />
    </Source>

  )
}
