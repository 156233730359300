import { ReactElement, useState, MouseEvent } from 'react'
import { Loader, Tooltip } from 'components'

import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  borderLess = 'borderLess',
  borderLessPrimary = 'borderLessPrimary',
  borderLessWhite = 'borderLessWhite',
  delete = 'delete',
  secondary = 'secondary',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xsmall = 'xsmall',
}

type Props = {
  text: string;
  onClick?: (e?: MouseEvent) => Promise<void> | void;
  onMouseEnter?: (e?: MouseEvent) => Promise<void> | void;
  style?: ButtonStyle;
  disabled?: boolean;
  icon?: ReactElement;
  size?: ButtonSize;
  className?: string;
  isAsync?: boolean;
  inline?: boolean;
  iconTooltip?: string;
}

export default function SimpleButton({
  text,
  onClick = () => { /* */ },
  onMouseEnter = () => { /* */ },
  style = ButtonStyle.primary,
  size = ButtonSize.small,
  disabled = false,
  icon = null,
  className = '',
  isAsync = false,
  inline = false,
  iconTooltip = undefined,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const classes = [
    'simple-button',
    `button--${size}`,
    `button--${style}`,
    style.includes('borderLess') && 'is-borderLess',
    (style.includes('borderLess') || style.includes('light')) && 'underline-text',
    disabled && 'disabled',
    inline && 'inline',
    isLoading && 'disabled',
    className && className,
  ].filter(Boolean).join(' ')

  const handleOnClick = async (e: MouseEvent) => {
    if (isAsync) setIsLoading(true)
    await onClick(e)
    setIsLoading(false)
  }

  return (
    <button
      type="button"
      className={classes}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      disabled={disabled || isLoading}
    >
      {!isLoading && !iconTooltip && icon}
      {!isLoading && iconTooltip && (
        <Tooltip placement="right" label={iconTooltip}>
          {icon}
        </Tooltip>
      )}
      {(!isLoading || inline) && text}
      {isLoading && <Loader variant="x-small" />}
    </button>
  )
}
