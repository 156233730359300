/* eslint-disable no-console */
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'components'
import AgentTool from './AgentTool'
import MapSelectionTool from './ContentSelectionTool'
import ContactTool from './ContactTool'
import HelpTool from './HelpTool'

import './ToolsPanel.scss'

export default function ToolsPanel() {
  const panelRef = useRef<HTMLDivElement>(null)
  const [hidden, setHidden] = useState(true)

  const handleToggle = () => setHidden(!hidden)
  const handleClose = () => setHidden(true)

  useEffect(() => {
    if (hidden) {
      panelRef.current?.scrollTo(0, 0)
    }
  }, [hidden])

  return (
    <div className={`tools-panel hide-scroll${hidden ? ' hidden' : ''}`} ref={panelRef}>
      {hidden
        ? (
          <Tooltip placement="right" label="Menu">
            <BurgerIcon className="close-burger" onClick={handleToggle} />
          </Tooltip>
        )
        : <CloseIcon className="close-burger" onClick={handleToggle} />}
      <AgentTool onClose={handleClose} hidden={hidden} />
      <MapSelectionTool onClose={handleClose} hidden={hidden} />
      <ContactTool onClose={handleClose} />
      <HelpTool onClose={handleClose} />
    </div>
  )
}
