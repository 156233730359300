import MAIN_API from 'config/config'
import { MapRef, MapRequest } from 'react-map-gl'
import { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { MapSourceToUpdate, MapURLParams } from 'types'
import { isLayerSelected, MapDataSignal, PerimeterSignals } from 'services'

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}

const refreshTiles = (mapRef: MutableRefObject<MapRef>, sourceToUpdate: MapSourceToUpdate): void => {
  if (mapRef.current) {
    const mapInstance = mapRef.current.getMap()
    const params = sourceToUpdate.params ? `&${new URLSearchParams(sourceToUpdate.params)}` : ''

    // eslint-disable-next-line max-len
    const tempCacheUrl = `${MAIN_API.proxy}/chartis/v2/layer/${sourceToUpdate.layer}/mvt_tile/${sourceToUpdate.view}/?x={x}&y={y}&z={z}${params}&#${Date.now()}`
    try {
      mapInstance.getSource(sourceToUpdate.mapboxSourceId).tiles = [tempCacheUrl]
      // Remove the tiles for a particular source
      mapInstance.style.sourceCaches[sourceToUpdate.mapboxSourceId].clearTiles()
      // Load the new tiles for the current viewport (mapInstance.transform -> viewport)
      mapInstance.style.sourceCaches[sourceToUpdate.mapboxSourceId].update(mapInstance.transform)
      // Force a repaint, so that the map will be repainted without you having to touch the map
      mapInstance.triggerRepaint()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
}

export const useMapURL = (
  layer: string,
  view: string,
  mapboxSourceId: string,
  mapRef: MutableRefObject<MapRef>,
  urlParams?: MapURLParams,
) => {
  const [url, setUrl] = useState<string>(`${MAIN_API.proxy}/chartis/v2/layer/${layer}/mvt/${view}/`)
  const [params, setParams] = useState<MapURLParams>(urlParams || {})

  // Clear tiles when the URL params change, needed to avoid cache issues
  useEffect(() => {
    if (urlParams && !isEqual(urlParams, params)) {
      setParams(urlParams)
      refreshTiles(mapRef, { layer, view, mapboxSourceId, params: urlParams })
    }
  }, [urlParams])

  useEffect(() => {
    setUrl(`${MAIN_API.proxy}/chartis/v2/layer/${layer}/mvt/${view}/?${new URLSearchParams(params)}`)
  }, [params])

  return url
}

export const useActivePerimeterParams = (paramKey: string) => {
  const params = useMemo(() => {
    if (PerimeterSignals.activePerimeter.value && paramKey && !PerimeterSignals.mode.value) {
      return {
        [paramKey]: JSON.stringify(PerimeterSignals.activePerimeter.value.geometry),
      }
    }
    return {}
  }, [PerimeterSignals.activePerimeter.value, paramKey, PerimeterSignals.mode.value])

  return params
}

export const useHoveredObjectsIds = () => {
  const [hoveredObjectsIds, setHoveredObjectsIds] = useState<string[]>([])
  useEffect(() => {
    setHoveredObjectsIds([
      ...MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || [],
      MapDataSignal.targetedObject.value?.properties?.id,
    ].filter(Boolean))
  }, [MapDataSignal.hoveredObjects.value, MapDataSignal.targetedObject.value])
  return hoveredObjectsIds
}

export const getCollectionStatus = () => {
  const visibility = [
    'collection-status-study', 'collection-status-waiting', 'collection-status-not-started',
    'collection-status-blocked', 'collection-status-done',
  ].some(layer => isLayerSelected(layer))

  const count: mapboxgl.Expression = [
    '+',
    ['case',
      ['boolean', isLayerSelected('collection-status-study')],
      ['coalesce', ['get', 'nombre_etude_en_cours'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-waiting')],
      ['coalesce', ['get', 'nombre_en_attente_rc'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-not-started')],
      ['coalesce', ['get', 'nombre_dc_non_commence'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-blocked')],
      ['coalesce', ['get', 'nombre_dc_bloque'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-done')],
      ['coalesce', ['get', 'nombre_dc_non_termine'], 0], 0,
    ],
  ]

  const filter: mapboxgl.Expression = [
    'any',
    ['!=', ['get', 'nombre_etude_en_cours'], null],
    ['!=', ['get', 'nombre_dc_bloque'], null],
    ['!=', ['get', 'nombre_dc_non_commence'], null],
    ['!=', ['get', 'nombre_en_attente_rc'], null],
    ['!=', ['get', 'nombre_dc_non_termine'], null],
  ]

  return { visibility, count, filter }
}

export const hoverColor = (color: string, ids: string[]): mapboxgl.Expression => (['case',
  ['in', ['get', 'id'], ['literal', ids]], '#cc0000',
  color,
])
