import React, { useEffect, useRef, useState } from 'react'
import { TooltipSignal } from './TooltipWrapper'

interface Props {
  placement: 'top' | 'bottom' | 'left' | 'right';
  label: string;
  children: JSX.Element;
}

export default function Tooltip({ placement, label, children }: Props) {
  const childRef = useRef<HTMLDivElement>(null)
  const [display, setDisplay] = useState(false)

  useEffect(() => () => {
    setDisplay(false)
    TooltipSignal.label.value = null
    TooltipSignal.style.value = null
  }, [])

  const updateStyle = () => {
    if (!childRef.current) return
    const element = childRef.current
    const bounding = element.getBoundingClientRect()

    TooltipSignal.label.value = label
    if (placement === 'top') {
      TooltipSignal.style.value = {
        top: bounding.top - 5,
        left: bounding.left + bounding.width / 2,
        transform: 'translate(-50%, -100%)',
      }
    } else if (placement === 'bottom') {
      TooltipSignal.style.value = {
        top: bounding.bottom + 5,
        left: bounding.left + bounding.width / 2,
        transform: 'translate(-50%, 0)',
      }
    } else if (placement === 'left') {
      TooltipSignal.style.value = {
        top: bounding.top + bounding.height / 2,
        left: bounding.left - 5,
        transform: 'translate(-100%, -50%)',
      }
    } else if (placement === 'right') {
      TooltipSignal.style.value = {
        top: bounding.top + bounding.height / 2,
        left: bounding.right + 5,
        transform: 'translate(0, -50%)',
      }
    }
  }

  const handleMouseLeave = () => {
    setDisplay(false)
    TooltipSignal.label.value = null
    TooltipSignal.style.value = null
  }

  const handleMouseEnter = () => {
    setDisplay(true)
    updateStyle()
  }

  const handleMovement = () => {
    if (!display) return
    updateStyle()
  }

  useEffect(() => {
    if (childRef.current) {
      childRef.current.addEventListener('mouseenter', handleMouseEnter)
      childRef.current.addEventListener('mouseleave', handleMouseLeave)
      document.addEventListener('resize', handleMovement, true)
      document.addEventListener('scroll', handleMovement, true)
    }

    return () => {
      if (childRef.current) {
        childRef.current.removeEventListener('mouseenter', handleMouseEnter)
        childRef.current.removeEventListener('mouseleave', handleMouseLeave)
      }
      document.removeEventListener('resize', handleMovement)
      document.removeEventListener('scroll', handleMovement)
    }
  }, [childRef?.current])
  if (!label) return children
  return (
    <>
      {React.cloneElement(children, { ref: childRef })}
    </>
  )
}
