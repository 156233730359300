/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { get } from '@osrdata/app_core/dist/requests'
import { useState, MouseEvent } from 'react'
import { Feature } from 'geojson'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { Collection, IndiceGep, InfoIs } from 'types'
import {
  Accordion, Button, ButtonSize, ButtonStyle, CodeSelectModal, Loader, ModalSignal,
} from 'components'
import { catchable } from 'utils'
import {
  CHARTIS_SOURCES_IDS,
  handleLocateObject,
  deleteFavoriteCollection,
  FavoritesSignal,
  postFavoriteCollection,
  MapDataSignal,
} from 'services'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from '../info/InfoSinglePanel'

import './CollectionPanel.scss'

type Props = {
  collection: Collection
  onBack?: () => void
}

export default function CollectionPanel({ collection, onBack }: Props) {
  const [loading, setLoading] = useState(false)
  const favoriteId = FavoritesSignal.collections.value.find(col => col.id === collection.id)?.favorite_id

  const handleFavorite = async () => {
    await postFavoriteCollection(collection)
  }

  const handleLocate = (id: string, layer: string) => async (e: MouseEvent) => {
    e.stopPropagation()
    setLoading(true)
    await handleLocateObject(id, layer)
    setLoading(false)
  }

  const handleFocus = (id: string) => async () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id } }]
  }

  const getIsLayer = (is: InfoIs) => {
    if (is.type === 'POSTE') {
      return CHARTIS_SOURCES_IDS.poste
    }

    return CHARTIS_SOURCES_IDS.bal
  }

  const handleClickIs = (is: InfoIs) => async (e: MouseEvent) => {
    handleLocate(is.gaia_id, getIsLayer(is))(e)
    if (is.type === 'POSTE') {
      const [, feature] = await catchable(() => get<Feature>(
        '/chartis/v2/layer/dexcarto_poste/geojson_feature/full_rgi_track_geo/',
        { id: is.gaia_id },
      ))
      PanelSignal.value = (
        <InfoSinglePanel
          feature={{ properties: feature.properties, source: 'dexcarto_poste', sourceLayer: 'dexcarto_poste' }}
          onBack={() => {
            PanelSignal.value = <CollectionPanel collection={collection} onBack={onBack} />
          }}
        />
      )
    }
  }

  const handleSelectC6 = (indice: IndiceGep) => () => {
    ModalSignal.value = (
      <CodeSelectModal
        title={`Lier l'indice ${indice.indice} - ${indice.objet}`}
        indice={indice}
        collection={collection}
      />
    )
  }

  return (
    <>
      <div className="panel-title collection-title">
        {onBack && (
          <Button
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
        )}
        <div className="title-container">
          <h3>
            <p className="title">
              {collection.nom_collection_gep}
              {loading && <Loader variant="x-small" />}
              {favoriteId
                ? <FavoriteSelectedIcon onClick={() => deleteFavoriteCollection(favoriteId)} />
                : <FavoriteIcon onClick={handleFavorite} />}
            </p>
          </h3>
          <p className="code-geo">
            <b>Code Géographique : </b>
            {collection.code_geographique}
          </p>
          <p className="ouranos-id">
            <b>ID Ouranos : </b>
            {collection.jira_id}
          </p>
        </div>
      </div>
      <div className="collection-panel hide-scroll">
        <Accordion title="IS de la collection" defaultExpanded>
          {collection.infos_is.map((is, index) => (
            <>
              <div key={is.id} className="is-item">
                <p>
                  <b>ID Ouranos : </b>
                  {is.id}
                </p>
                <p>
                  <b>Nom de l’IS : </b>
                  <span>{is.libelle_long}</span>
                </p>
                <p>
                  <b>Type : </b>
                  {is.type}
                </p>
                <p>
                  <b>Technologie : </b>
                  {is.poste_technologie_libelle || 'N/C'}
                </p>
                <p>
                  <b>Ferrolocalisation : </b>
                  {`${is.ligne_id} - ${is.localisation}`}
                </p>
                <div className="small-separator" />
                <p>
                  <b>Gaia : </b>
                  {is.gaia_match ? 'Oui' : 'Non'}
                </p>
                {is.gaia_match && (
                  <p>
                    <b>Libellé Gaia : </b>
                    <Button
                      style={ButtonStyle.borderLess}
                      text={is.poste_libelle_long || 'N/C'}
                      size={ButtonSize.medium}
                      inline
                      onClick={handleClickIs(is)}
                      disabled={!is.poste_libelle_long || loading}
                    />
                  </p>
                )}
                {is.gaia_match && (
                  <p>
                    <b>ID Gaia : </b>
                    {is.gaia_id || 'N/C'}
                  </p>
                )}
              </div>
              {index < collection.infos_is.length - 1 && <div className="separator" />}
            </>
          ))}
        </Accordion>

        <div className="separator" />

        <Accordion title="Interactions" defaultExpanded>
          {collection.infos_is.map((is, index) => (
            <>
              <div key={is.id} className="is-item">
                <p>
                  <b>{'IHM : '}</b>
                  <span>{is.poste_ihms.length ? 'Oui' : 'Aucune IHM Ouranos identifiée pour cette collection'}</span>
                  {is.poste_ihms.length > 0
                  && is.poste_ihms.map((ihm, i) => (
                    <div key={ihm.id}>
                      <p>
                        <b>Libellé IHM : </b>
                        <Button
                          style={ButtonStyle.borderLess}
                          text={`${ihm.libelle}${(i + 1) < is.poste_ihms.length ? ', ' : ''}`}
                          size={ButtonSize.medium}
                          inline
                          onClick={handleLocate(ihm.id, CHARTIS_SOURCES_IDS.ihm)}
                          disabled={loading}
                        />
                      </p>
                      <p>
                        <b>{'ID Ouranos : '}</b>
                        <span>{`${ihm.id}${(i + 1) < is.poste_ihms.length ? ', ' : ''}`}</span>
                      </p>
                    </div>
                  ))}
                </p>
              </div>
              {index < collection.infos_is.length - 1 && <div className="small-separator" />}
            </>
          ))}
          <div className="small-separator" />
          <div className="encadrants d-flex">
            <b>Encadrant(s) : </b>
            {collection.postes_encadrants.map(poste => (
              <Button
                key={poste.id}
                style={ButtonStyle.borderLess}
                text={poste.libelle_long}
                size={ButtonSize.medium}
                inline
                onClick={handleLocate(poste.id, CHARTIS_SOURCES_IDS.poste)}
                onMouseEnter={handleFocus(poste.id)}
                disabled={loading}
              />
            ))}
            {collection.bals_encadrants.map(bal => (
              <Button
                key={bal.id}
                style={ButtonStyle.borderLess}
                text={bal.libelle}
                size={ButtonSize.medium}
                inline
                onClick={handleLocate(bal.id, CHARTIS_SOURCES_IDS.bal)}
                disabled={loading}
              />
            ))}
            {collection.bals_encadrants.length === 0 && collection.postes_encadrants.length === 0 && 'Aucun'}
          </div>
        </Accordion>

        <div className="separator" />

        <Accordion title="Indices GEP" defaultExpanded>
          <table>
            <thead>
              <tr>
                <th>Indice</th>
                <th>Code C6</th>
                <th>Statut</th>
                <th>Projet / Travaux</th>
              </tr>
            </thead>
            <tbody>
              {collection.indices_gep.map(indice => (
                <tr key={indice.id}>
                  <td>{indice.indice}</td>
                  <td className="c6-field" style={{ minWidth: !indice.code_c6 ? '170px' : '90px' }}>
                    <Button
                      size={indice.code_c6 ? ButtonSize.small : ButtonSize.xsmall}
                      style={!indice.code_c6 ? ButtonStyle.light : ButtonStyle.borderLess}
                      text={indice.code_c6 || 'Renseigner Code C6'}
                      onClick={handleSelectC6(indice)}
                      icon={indice.code_c6 && <EditIcon />}
                    />
                  </td>
                  <td>{indice.categorie}</td>
                  <td>{indice.objet}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Accordion>

        <div className="separator" />

        {(collection.collection_idf?.nom_collection_idf && (
          <>
            <Accordion title="Retours Chantiers" defaultExpanded>
              <p>
                <b>{'Collection IDF : '}</b>
                <span>{collection.collection_idf.nom_collection_idf}</span>
              </p>
              <table style={{ width: 'fit-content' }}>
                <tr>
                  <td>Etudes en cours</td>
                  <td>{collection.collection_idf.nombre_etude_en_cours}</td>
                </tr>
                <tr>
                  <td>RC en attente</td>
                  <td>{collection.collection_idf.nombre_en_attente_rc}</td>
                </tr>
                <tr>
                  <td>DC réalisables non lancés</td>
                  <td>{collection.collection_idf.nombre_dc_non_commence}</td>
                </tr>
                <tr>
                  <td>DC bloqués</td>
                  <td>{collection.collection_idf.nombre_dc_bloque}</td>
                </tr>
                <tr>
                  <td>DC en cours</td>
                  <td>{collection.collection_idf.nombre_dc_non_termine}</td>
                </tr>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Indice</th>
                    <th>Statut</th>
                    <th>Projet / Travaux</th>
                  </tr>
                </thead>
                <tbody>
                  {collection.collection_idf.indices.map(indice => (
                    <tr key={indice.id}>
                      <td>{indice.name}</td>
                      <td>{indice.etat_collection}</td>
                      <td>{indice.libelle_operation}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>
            <div className="separator" />
          </>
        ))}

        <Accordion title="Fiches" defaultExpanded>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Clé</th>
                <th>Indice</th>
                <th>Statut</th>
                <th>Résumé</th>
              </tr>
            </thead>
            <tbody>
              {collection.fiches.map(fiche => (
                <tr
                  key={fiche.id}
                  className="interactive"
                  onClick={() => {
                    window.open(`https://${fiche.url}`, '_blank')
                  }}
                >
                  <td>{fiche.type}</td>
                  <td>{fiche.key}</td>
                  <td>{fiche.indice}</td>
                  <td>{fiche.statut}</td>
                  <td>{fiche.libelle}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Accordion>
      </div>
    </>
  )
}
